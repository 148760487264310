import Dexie from 'dexie';

export const db = new Dexie('TaskOrbiter');

db.version(7).stores({
    items: 'id',
    favorites: 'item_id',
    lists: 'id',
    users: 'id',
    myTasks: 'item_id',
    topics: 'id'
});