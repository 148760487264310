import Vue from 'vue';
import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import axios from 'axios';
import VueHead from 'vue-head';
import moment from 'moment';
import { createToastInterface } from 'vue-toastification';

import Icon from './components/common/Icon.vue';
import IconButton from '@/components/IconButton.vue';
import ListItem from '@/components/common/ListItem.vue';
import UpsellMessage from '@/components/common/UpsellMessage.vue';
import ToTextIconButton from '@/components/buttons/TextIconButton.vue';
import ToTooltip from '@/components/wrappers/Tooltip.vue';
import ToBadge from '@/components/wrappers/Badge.vue';
import eventbus from '@/mixins/eventBus';
import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import { db } from '@/utility/db';

Vue.config.productionTip = false;

// Register common components
Vue.component('to-icon', Icon);
Vue.component('to-icon-button', IconButton);
Vue.component('to-list-item', ListItem);
Vue.component('to-upsell-message', UpsellMessage);
Vue.component('to-text-icon-button', ToTextIconButton);
Vue.component('to-tooltip', ToTooltip);
Vue.component('to-badge', ToBadge);

Vue.prototype.$eventBus = eventbus;
Vue.prototype.$http = axios.create({
  withCredentials: true
});
Vue.prototype.$moment = moment;
Vue.prototype.$db = db;
Vue.prototype.$savedState = store.state.savedState;

Vue.prototype.$appMap = {
  orbit: 'Tasks',
  person: 'People',
  group: 'People',
  view: 'Views',
  call: 'Calls',
  topic: 'Chat',
  dm: 'Chat'
};

Vue.prototype.$getDmName = (members) => {
  // Filter out the current user if there is more than one member
  const filteredMembers = members.length > 1
    ? members.filter(member => !member.is_curr_user)
    : members;

  // Map the names and join them with ", "
  const combinedNames = filteredMembers.map(member => member.name).join(', ');

  return combinedNames;
};

Vue.prototype.$isNumeric = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str)) && typeof str === 'number' && /^\d+(\.\d+)?$/.test(str);
}

Vue.prototype.$getIcon = (item_type) => {
  if (item_type === 'call') return 'video-outline';
  if (item_type === 'view') return 'page-outline';
  if (item_type === 'person') return 'person';
  if (item_type === 'group') return 'people';
  if (item_type === 'topic') return 'topic';
  return 'circle-check-outline';
}

Vue.prototype.$decodeText = function(encodedString) {
  if (typeof encodedString !== 'string') return encodedString;
  if (!encodedString) return null;
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(`<!doctype html><body>${encodedString}`, 'text/html').body.textContent;
  return decodedString;
}

Vue.prototype.$getDisplayDate = function(date, shouldShowTime = false) {
  if (!date) return;
  const momentDate = moment(date);
  const chosenDate = momentDate.startOf('day');
  const today = moment().startOf('day');
  const diff = chosenDate.diff(today, 'days');
  const hasTime = date.includes(':');

  if (diff === -1) {
    return shouldShowTime && hasTime ? `Yesterday, ${moment(date).format('h:mm a')}` : 'Yesterday';
  }
  if (diff === 0) {
    return shouldShowTime && hasTime ? `Today, ${moment(date).format('h:mm a')}` : 'Today';
  }
  if (diff === 1) {
    return shouldShowTime && hasTime ? `Tomorrow, ${moment(date).format('h:mm a')}` : 'Tomorrow';
  }
  if (diff > 1 && diff < 8) {
    return shouldShowTime && hasTime ? moment(date).format('dddd, h:mm a') : moment(date).format('dddd');
  }
  return shouldShowTime && hasTime ? moment(date).format('LLL') : moment(date).format('LL');
}

Vue.prototype.$getDateClass = function(date) {
  if (!date) return;
  const momentDate = this.$moment(date);
  const chosenDate = momentDate.startOf('day');
  const today = this.$moment().startOf('day');
  const diff = chosenDate.diff(today, 'days');
  const hasTime = date.includes(':');
  
  if (diff < 0) return 'is-important';
  if (diff === 0) {
    if (hasTime) {
      const currentTime = this.$moment();
      const chosenTime = this.$moment(date);
      if (currentTime.isAfter(chosenTime)) return 'is-important';
    }
    return 'is-today';
  }
  if (diff === 1) return 'is-tomorrow';
  if (diff > 0 && diff <= 7) return 'is-next-7-days';
  return;
}

Vue.prototype.$isTemporaryId = function(id) {
  return id.includes('-');
}

Vue.use(PortalVue);
Vue.use(vClickOutside);
Vue.use(VueHead, {
  separator: '-',
});

let isRefreshing = false;
let refreshPromise = null;

Vue.prototype.$http.interceptors.response.use(
  (response) => {
    if (response.data && response.data.redirect) {
      router.push(response.data.redirect).catch(() => {});
    }
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = new Promise(async (resolve, reject) => {
          try {
            await axios.post('/api/auth/refresh', {}, { withCredentials: true });
            isRefreshing = false;
            resolve();
          } catch (refreshError) {
            store.commit('logOut');
            isRefreshing = false;
            reject(refreshError);
          }
        });
      }

      try {
        await refreshPromise;
        return Vue.prototype.$http(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const toast = createToastInterface({
  timeout: 4000,
  shareAppContext: true,
  position: window.innerWidth < 600 ? 'top-center' : 'bottom-center',
  icon: false,
  closeButton: false,
  newestOnTop: true,
  closeOnClick: true,
  draggable: false,
  transition: 'toast-fade'
});

Vue.prototype.$toast = (msg, options) => {
  const toastId = toast(msg, options);
    
  setTimeout(() => {
    toast.dismiss(toastId);
  }, 5000);
}