<template>
  <inline-svg
    :src="svg"
    class="icon"
    :class="`icon-${name}`"
  />
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    InlineSvg,
  },
  computed: {
    svg() {
      // eslint-disable-next-line
      try {
        return require(`@/assets/icons/${this.name}.svg`).default;
      } catch {
        this.$emit('error');
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
.icon {
  user-select: none;
}
</style>