<template>
  <div class="auth-wrapper">
    <div class="auth-wrapper__center">
      <div class="auth-wrapper__header flex flex--align flex--sb">
        <a href="https://taskorbiter.com">
          <to-icon name="taskorbiter" />
        </a>
        <div class="auth-wrapper__header-right">
          <slot name="header" />
          <a
            v-if="$store.state.isLoggedIn && !hideLogOut"
            @click.prevent="logOut()"
          >
            Log Out
          </a>
        </div>
      </div>
      <div :class="largeWidth" :style="{ maxWidth: maxWidth }" class="auth-wrapper__center-content flex flex--column">
        <slot name="title" />
        <slot name="sub-title" />
        <div class="auth-wrapper__content">
          <slot />
        </div>
        <slot name="bottom" />
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthWrapper',
  props: {
    maxWidth: {
      type: String,
      default: '500px' 
    },
    largeWidth: {
      type: String,
      default: ''
    },
    illustration: {
      type: String, 
      default: "illustration-right"
    },
    hideLogOut: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.focusFirstInput();
  },
  methods: {
    async focusFirstInput() {
      await this.$nextTick();
      const inputs = this.$el.querySelectorAll('input[type=\'email\'], input[type=\'text\']');
      if (inputs && inputs.length) inputs[0].focus();
    },
    logOut() {
      this.$store.dispatch('logOut');
      this.$router.push('/login').catch(() => {});
    },
  },
};
</script>

<style lang="scss">
$mobile-bp: 1000px;

.auth-wrapper {
  display: flex;
  height: 100vh;
  background-color: var(--foreground-color);
  overflow-y: auto;
  gap: 15px;

  .log {
    font-weight: 700;
    font-size: 40px;
  }

  &__header {
    background-color: var(--foreground-color);
    z-index: 1;
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0 28px;
    height: 80px;
    flex-shrink: 0;

    a {
      cursor: pointer;
    }

    svg {
      height: 30px;
      width: auto;
      color: var(--text-color);
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__center {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 20px);
    width: 100%;
    flex-shrink: 0;

    @media screen and (max-width: $mobile-bp) {
      width: 100%;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: fit-content;
      width: 100%;
      padding: 10px;

      @media screen and (max-width: $mobile-bp) {
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    z-index: 5;

    .is-mobile & {
      padding: 0 15px;
      margin-bottom: 20px;
    }

    body:not(.is-mobile) & {
      padding: 20px;
      border-radius: 10px;
      @include mode(background-color, var(--light-highlight), var(--background-color));
      border: 1px solid var(--light-border);
      box-shadow: rgba(0,0,0,.04) 0 1px 0, rgba(0,0,0,.05) 0 2px 7px, rgba(0,0,0,.06) 0 12px 22px;
    }

    .text-icon-button {
      width: 100%;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .support-button {
    
    svg {
      width: 20px;
    }

  }

  a:hover:not([class^='list-item'], [class^='sidebar-list-item']) {
    text-decoration: underline;
  }

  .input input {
    @include mode(background-color, var(--light-highlight), var(--background-color));
  }

  h1 {
    font-weight: 600;
  }
}
</style>
