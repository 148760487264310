import store from '../store/store';

export function requireAuth(to, from, next) {
    if (store.state.isLoggedIn) next();
    else if (to.path === '/') next({ name: 'login' });
    else next({ name: 'login', query: { redirect: to.fullPath } });
}

export function requireNoAuth(to, from, next) {
    if (store.state.isLoggedIn && !!to.query.error) store.state.isLoggedIn = false;
    if (store.state.isLoggedIn) next({ name: 'dashboard' });
    else next();
}