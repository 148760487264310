<template>
    <div class="upsell-message flex flex--column">
        <span class="upsell-message__title heavy px-2 pt-2 pb-0">{{ title }}</span>
        <span class="upsell-message__message m-0 px-2 pb-2">{{ message }}</span>
        <button
            class="upsell-message__upgrade m-0 py-3 px-3 heavy"
            @click="openUpgradeModal()"
        >
            Upgrade Now
        </button>
    </div>
</template>

<script>
export default {
    name: 'UpsellMessage',
    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        openUpgradeModal() {
            this.$eventBus.$emit('choose-plan', true);
        }
    }
}
</script>

<style lang="scss">
.upsell-message {
    font-size: 13px;
    border-radius: 5px;
    gap: 5px;
    overflow: hidden;
    @include mode(color, white, black);
    background-color: white;
    border: 1px solid var(--primary);
    // @include mode(border, 1px solid rgba(white, .15), 1px solid rgba(black, .2));
    white-space: initial;

    &__message {
        color: rgba(black, .5);
    }

    &__upgrade {
        background-color: var(--primary);
        text-align: center;
        transition: .25s;
        font-size: inherit;
        color: white;

        &:hover {
            background-color: #2b6fd4;
        }
    }
}
</style>