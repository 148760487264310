import { requireAuth, requireNoAuth } from '@/mixins/routerMixins';

export default [
    {
        path: '/signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "signup" */ '@/router/signup/Signup.vue'),
        beforeEnter: requireNoAuth
    },
    {
        path: '/signup/create-profile',
        name: 'signup-create-profile',
        component: () => import(/* webpackChunkName: "create-profile" */ '@/router/signup/CreateProfile.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/choose-use-case',
        name: 'signup-use-case',
        component: () => import(/* webpackChunkName: "choose-use-case" */ '@/router/signup/ChooseUseCase.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/choose-your-work',
        name: 'signup-choose-your-work',
        component: () => import(/* webpackChunkName: "choose-your-work" */ '@/router/signup/ChooseYourWork.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/team/create-workspace',
        name: 'signup-create-team-workspace',
        component: () => import(/* webpackChunkName: "create-team-workspace" */ '@/router/signup/CreateTeamWorkspace.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/personal/create-your-workspace',
        name: 'signup-create-your-workspace',
        component: () => import(/* webpackChunkName: "create-your-workspace" */ '@/router/signup/CreateYourWorkspace.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/invite-others',
        name: 'signup-invite-others',
        component: () => import(/* webpackChunkName: "invite-others" */ '@/router/signup/InviteOthers.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/signup/getting-things-ready',
        name: 'signup-getting-things-ready',
        component: () => import(/* webpackChunkName: "getting-things-ready" */ '@/router/signup/GettingThingsReady.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import(/* webpackChunkName: "verify" */ '@/router/signup/VerifyAccount.vue'),
        beforeEnter: requireAuth
    }
];