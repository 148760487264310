<template>
  <div
    class="input"
    :class="{
      'input--editing': !!editor,
      'input--icon': $slots.icon
    }"
    @click="$emit('click')"
  >
    <label v-if="label">{{ label }}</label>
    <div class="input__input">
      <div v-if="$slots.icon" class="input__icon">
        <slot name="icon" />
      </div>
      <input
        ref="input"
        v-model="internalValue"
        v-bind="$attrs"
        :type="type"
        :placeholder="placeholder"
        :disabled="!!editor || disabled"
        :class="{'input--no-focus-border': disableFocusBorder, 'input--no-border': disableFocusBorder}"
        @input="onInput"
        @focus="handleFocus()"
        @blur="handleBlur()"
      />
      <div v-if="!!editor" class="input__slot">
        <!-- Insert Avatar -->
      </div>
      <div v-else-if="$slots.default" class="input__slot">
        <slot />
      </div>
      <div v-if="$slots.hoverSlot" class="input__hover-slot">
        <slot name="hoverSlot" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    editor: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disableFocusBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      focused: false,
    };
  },
  watch: {
    value: {
      handler() {
        if (!this.focused) this.internalValue = this.value;
      },
      immediate: true,
    },
    internalValue() {
      if (this.editor) return;
      this.$emit('input', this.internalValue);
    },
  },
  async mounted() {
    if (!this.autofocus) return;
    await this.$nextTick();
    this.$refs.input.focus();
  },
  methods: {
    handleFocus() {
      if (this.editor) return;
      this.focused = true;
      this.$emit('focus');
    },
    handleBlur() {
      if (this.editor) return;
      this.focused = false;
      this.$emit('blur', this.internalValue);
    },
    focus() {
      this.$refs.input.focus();
    },
    selectAll() {
      this.$refs.input.select();
    },
    onInput(event) {
      if (this.type !== 'tel' && this.type !== 'number') return;

      let newValue = event.target.value;

      this.internalValue = newValue.replace(/[^0-9.,]/g, '');
    }
  },
};
</script>


<style lang="scss">
.input {
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid var(--light-border);
    background-color: var(--background-color);
    color: var(--text-color);
    outline: none;
    transition: all .2s;

    &::placeholder {
      color: var(--text-opacity--5);
    }

    &:focus {
      border: 1px solid var(--primary);
      transition: all .2s;
    }

    &.input--no-focus-border:focus,
    &.input--no-border {
      border: none;
    }

    .popover__content & {
      padding: 6px;
    }
  }

  &__input {
    position: relative;
    color: var(--text-color);

    &:hover .input__hover-slot {
      opacity: 1;
    }
  }

  &__icon,
  &__slot,
  &__hover-slot {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    color: var(--text-opacity--5);
  }

  &__icon {
    left: 0;
    padding-left: 10px;
  }

  &__slot {
    right: 0;
    padding-right: 10px;
  }

  &__hover-slot {
    right: 10px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &--editing {
    input {
      box-shadow: 0 0 0 2px var(--primary);
    }
  }

  :root &--icon {
    input {
      padding-left: calc(20px + 1.5em);
    }
  }
}
</style>
