<template>
  <div class="validation">
    <slot />
    <to-toggle-section :expanded="!!error">
      <div class="validation__content flex flex--align pt-1 pb-2">
        <to-icon name="alert-circle" class="mr-1" />
        {{ error }}
      </div>
    </to-toggle-section>
  </div>
</template>

<script>
import ToToggleSection from '@/components/ToggleSection.vue';

export default {
  name: 'Validation',
  props: {
    error: {
      type: String,
      default: null,
    },
  },
  components: {
    ToToggleSection,
  },
};
</script>

<style lang="scss">
.validation {

  &__content {

    svg {
      color: #e74c3c;
    }
  }
}
</style>
