<template>
  <div
    class="toggle-section"
    :class="{
      'toggle-section--transition': transition,
      'toggle-section--overflow-hidden': transitioning || !expanded,
    }"
    :style="`height: ${height};`"
  >
    <div ref="section" :inert="!expanded">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSection',
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    loadTransition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: '',
      transition: false,
      transitioning: false,
    };
  },
  created() {
    if (!this.loadTransition) this.transition = true;
  },
  watch: {
    expanded(value) {
      this.setHeight();

      if (value) this.transitioning = true;
    },
  },
  async mounted() {
    await this.$nextTick();
    if (!this.expanded) this.height = 0;

    this.$el.addEventListener('transitionend', this.onTransitionEnd);
    this.$el.addEventListener('transitionstart', this.onTransitionStart);
  },
  beforeDestroy() {
    this.$el.removeEventListener('transitionend', this.onTransitionEnd);
    this.$el.removeEventListener('transitionstart', this.onTransitionStart);
  },
  methods: {
    setHeight() {
      if (this.expanded) {
        this.height = 0;
        setTimeout(() => {
          this.height = `${this.$refs.section.clientHeight}px`;
        });
      } else {
        this.height = `${this.$refs.section.clientHeight}px`;
        setTimeout(() => {
          this.height = 0;
        });
      }
    },
    onTransitionEnd() {
      if (this.expanded) {
        this.height = '';
        this.transitioning = false;
      } else {
        this.$emit('closed');
      }
    },
    onTransitionStart(e) {
      if (e.target === this.$refs.toggle) this.transitioning = true;
    }
  },
};
</script>

<style lang="scss">
.toggle-section {
  will-change: height;

  &--transition {
    transition: height .25s;
  }

  &--overflow-hidden {
    overflow: hidden;
  }
}
</style>
