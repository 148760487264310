<template>
    <div class="social-buttons">
        <button @click="authenticate('google')" :disabled="!!loading">
            <to-icon v-show="loading === 'google'" name="loading" />
            <template v-if="loading !== 'google'">
                <img :src="require('@/assets/images/social/google.svg').default">
                <span>Continue with Google</span>
            </template>
        </button>
        <button @click="authenticate('microsoft')" :disabled="!!loading">
            <to-icon v-show="loading === 'microsoft'" name="loading" />
            <template v-if="loading !== 'microsoft'">
                <img :src="require('@/assets/images/social/microsoft.svg').default">
                <span>Continue with Microsoft</span>
            </template>
        </button>
        <div class="social-buttons__or" />
    </div>
</template>

<script>
export default {
    name: 'SocialButtons',
    data() {
        return {
            loading: null
        }
    },
    methods: {
        authenticate(provider) {
            this.loading = provider;
            let route = `/api/social/${provider}`;
            const invite = this.$route.query.invite;
            const join = this.$route.query.join;
            const redirect = this.$route.query.redirect;
            const template = this.$route.query.template;

            // Construct query parameters
            let queryParams = [];
            if (invite) queryParams.push(`invite=${invite}`);
            if (join) queryParams.push(`join=${join}`);
            if (redirect) queryParams.push(`redirect=${encodeURIComponent(redirect)}`);
            if (template) queryParams.push(`template=${template}`);

            // Append query parameters to route if there are any
            if (queryParams.length > 0) {
                route += `?${queryParams.join('&')}`;
            }

            this.$store.dispatch('isLoggedIn', true);
            window.location.href = route;
        }
    }
}
</script>

<style lang="scss">
.social-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;

    .is-mobile & {
        padding: 0 15px;
    }

    &__or {
        position: relative;
        width: 100%;
        border-top: 1px solid var(--light-highlight);
        margin: 20px 0;

        &::before {
            content: 'or';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-transform: uppercase;
            padding: 0 25px;
            font-weight: 600;
            color: var(--text-opacity--5);
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 10px;
        padding: 10px;
        flex-grow: 1;
        font-weight: 600;
        @include mode(background-color, var(--light-highlight), var(--background-color));
        border-radius: 10px;
        border: 1px solid var(--light-border);
        box-shadow: rgba(0,0,0,.04) 0 1px 0, rgba(0,0,0,.05) 0 2px 7px;
        font-size: 14px;
        transition: background-color .2s;

        &:hover {
            @include mode(background-color, var(--light-border), var(--light-highlight));
        }

        img,
        svg {
            height: 20px;
            width: auto;
        }
    }
}
</style>