<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      $isMobile: () => this.isMobile
    }
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    isMobile() {
      if (!this.viewportWidth) return;
      return this.viewportWidth < 600;
    }
  },
  watch: {
    isMobile: {
      handler(val) {
        if (val) {
          document.body.classList.add('is-mobile');
        } else {
          document.body.classList.remove('is-mobile');
        }
      },
      immediate: true
    },
    darkMode: {
      handler() {
        if (this.darkMode) {
          document.documentElement.classList.add('dark');
          document.documentElement.classList.remove('light');
        } else {
          document.documentElement.classList.remove('dark');
          document.documentElement.classList.add('light');
        }
      },
      immediate: true,
    }
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    // Set dark mode
    this.checkDarkMode();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.checkDarkMode);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  data() {
    return {
      viewportWidth: null
    }
  },
  methods: {
    handleResize() {
      this.viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    },
    checkDarkMode() {
      const osSetting = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (this.$store.state.darkModeFollowsOS) {
        this.$store.dispatch('setDarkMode', {
          darkMode: osSetting,
          darkModeFollowsOS: true,
        });
      }
    }
  },
};
</script>
