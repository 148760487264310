<template>
  <to-auth-wrapper
    class="login"
    max-width="475px"
    hide-log-out
  >
    <template #title>
      <h1 class="heavy sz-7 mb-4">Log In</h1>
    </template>
    <template #sub-title>
      <to-validation v-if="errorMessage" :error="errorMessage" class="mb-4" />
      <to-social-buttons />
    </template>
    <form @submit.prevent="onSubmit">
      <to-validation
        :error="errors.email"
        class="mb-4"
      >
        <div v-if="$slots.icon" class="input__icon">
          <slot name="icon" />
        </div>
        <to-input
          v-model="email"
          placeholder="Enter your email..."
          label="Email"
          type="email"
        >
          <template #icon>
            <to-icon name="at-sign" class="sz-2" />
          </template>
        </to-input>
      </to-validation>
      <to-validation
        :error="errors.password"
        class="mb-6"
      >
        <to-input
          v-model="password"
          label="Password"
          placeholder="Enter your password..."
          type="password"
        >
          <template #icon>
            <to-icon name="lock" class="sz-2" />
          </template>
        </to-input>
      </to-validation>
      <to-text-icon-button
        class="mt-3"
        type="submit"
        text="Log In"
        :disabled="!email || !password || loading"
        :is-loading="loading"
      />
    </form>
    <template #bottom>
      <div v-if="!inviteId" class="flex flex--sb flex--align mt-6">
        <router-link to="/passwordreset">Forgot Password</router-link>
        <span class="mx-3">·</span>
        <router-link to="/signup">Create an Account</router-link>
      </div>
    </template>
    <template #footer>
      <div class="mb-7">
        <span class="opacity--5"><a href="https://taskorbiter.com/terms-and-privacy/" target="_blank">Terms &amp; Privacy</a></span>
      </div>
    </template>
  </to-auth-wrapper>
</template>

<script>
import ToAuthWrapper from '@/components/wrappers/AuthWrapper.vue';
import ToInput from '@/components/fields/Input.vue';
import ToValidation from '@/components/fields/Validation.vue';
import ToSocialButtons from '@/components/SocialButtons.vue';

export default {
  name: 'LogIn',
  components: {
    ToAuthWrapper,
    ToInput,
    ToValidation,
    ToSocialButtons
  },
  computed: {
    hasId() {
      return !!this.inviteId || !!this.joinId;
    },
    inviteId() {
      return this.$route.query.invite;
    },
    joinId() {
      return this.$route.query.join;
    },
    redirect() {
      return this.$route.query.redirect;
    },
    templateId() {
      return this.$route.query.template;
    },
    loginRoute() {
      if (!!this.inviteId) return '/api/invites/login';
      if (!!this.joinId) return '/api/invite-link/login';
      return'/api/auth/login' ;
    },
    errorMessage() {
      return this.$route.query.error;
    }
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      errors: {},
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errors = {};
      this.$http.post(this.loginRoute, {
        email: this.email,
        password: this.password,
        inviteId: this.inviteId,
        joinId: this.joinId
      })
        .then((res) => {
          this.$store.dispatch('isLoggedIn', true);
          if (this.templateId) {
            this.$router.push(
              {
                name: 'dashboard',
                query: {
                  template: this.templateId,
                },
              },
            ).catch(() => {});
          } else if (this.redirect) {
            this.$router.push(this.redirect).catch(() => {});
          } else if (res.data.redirect) {
            this.$router.push(res.data.redirect).catch(() => {});
          } else {
            this.$router.push('/').catch(() => {});
          }
        })
        .catch((err) => {
          this.errors = err.response.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">

</style>
