<template>
  <div
    class="list-item flex flex--align"
    :class="{
      'list-item--has-right' : $slots.right,
      'list-item--has-left' : $slots.left,
      'list-item--disabled' : disabled,
      'is-selected' : isSelected
    }"
  >
    <div
      v-if="$slots.left"
      class="list-item__left flex flex--align flex--center flex--no-shrink"
    >
      <slot name="left" />
    </div>
    <component
      :is="to ? 'router-link' : href ? 'a' : 'div'"
      :to="to"
      :href="href"
      :disabled="disabled"
      v-bind="$attrs"
      @click="onClick()"
      class="list-item__el flex flex--align flex--sb min-w-0 w-100 left"
    >
      <div class="list-item__content flex flex--align flex--grow min-w-0">
        <div
          v-if="$slots.icon"
          class="list-item__icon flex flex--align flex--center flex--no-shrink mr-2"
          :class="iconClass"
        >
          <slot name="icon" />
        </div>
        <div class="flex flex--column flex--grow min-w-0">
          <span
            class="list-item__text nowrap"
            :class="titleClass"
          >
            <slot v-if="$slots.default" />
            <template v-else>{{ title }}</template>
          </span>
          <div v-if="$slots.bottom">
            <slot name="bottom" />
          </div>
          <span v-else-if="description || $slots.description" class="list-item__description sz-3 nowrap">
            <slot v-if="$slots.description" name="description" />
            <template v-else>{{ description }}</template>
          </span>
        </div>
        <div v-if="$slots.innerRight" class="flex--no-shrink ml-2">
          <slot name="innerRight" />
        </div>
      </div>
    </component>
    <div
      v-if="$slots.right"
      class="list-item__right flex flex--align flex--center flex--no-shrink"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      default: null,
    },
    title: {
      type: String,
    },
    titleClass: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    iconClass: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.list-item {
  transition: background-color .25s;

  &--disabled {
    pointer-events: none;
  }

  &--has-right {

    .list-item__content {
      padding-right: 10px;
    }
  }

  &.is-selected,
  &.router-link-exact-active {
    @include mode(background-color, #545454, #D6D9DD);
  }

  &:not(.no-hover) {
    cursor: pointer;

    &:hover {
      @include mode(background-color, #545454, #EDEEF0);
      text-decoration: none;
    }
  }

  &:not(:first-child).border-top {
    @include mode(border-top, 1px solid rgba(white, .15), 1px solid rgba(black, .2));
  }

  &:not(:last-child).border-bottom {
    @include mode(border-bottom, 1px solid rgba(white, .15), 1px solid rgba(black, .2));
  }

  &__icon {

    &.circle,
    &.action {
      width: 20px;
      height: 20px;
      padding: 4px;
      border-radius: 50%;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.circle {
      @include mode(background-color, #5F5F5F, #CCCCCC);
      @include mode(color, white, black);
    }

    .icon-button.icon-button {
      width: 20px;
      height: 20px;
      font-size: 8px;
    }
  }

  &__content {
    padding: 10px 15px;
  }

  &__text {
    font-size: 15px;
    flex-grow: 1;
  }

  &__left {
    padding-left: 15px;
    height: 100%;
  }

  &__right {
    padding-right: 15px;
    height: 100%;
  }

  :root &.single-list-item .list-item__content {
    padding: 10px 15px;
  }
}
</style>
