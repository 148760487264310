<template>
    <div
        ref="tooltipContent"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
        class="tooltip"
    >
        <slot />
    </div>
</template>

<script>
import tippy from 'tippy.js';

export default {
    name: 'Tooltip',
    inject: ['$isMobile'],
    props: {
        tooltipText: {
            type: String,
            default: ''
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            shouldShowTooltip: false,
            tooltipInstance: null
        };
    },
    computed: {
        isDisabled() {
            return this.disabled || this.$isMobile();
        }
    },
    watch: {
        tooltipText: {
            handler(content) {
                if (!this.tooltipInstance) return;
                this.tooltipInstance.setContent(content);
            },
            immediate: true
        },
        isDisabled: {
            handler(val) {
                this.$nextTick(() => {
                    if (!this.tooltipInstance) return;
                    if (val) this.tooltipInstance.disable();
                    else this.tooltipInstance.enable();
                });
            },
            immediate: true,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initTooltip();
        });
    },
    beforeDestroy() {
        this.destroyTooltip();
    },
    methods: {
        initTooltip() {
            this.tooltipInstance = tippy(this.$refs.tooltipContent, {
                content: this.tooltipText,
                allowHTML: true,
                arrow: false,
                animation: null,
                interactive: true,
                placement: this.placement,
                appendTo: document.body,
                theme: 'tooltip'
            });

            if (this.isDisabled) this.tooltipInstance.disable();
        },
        destroyTooltip() {
            if (this.tooltipInstance) {
                this.tooltipInstance.destroy();
            }
        },
        showTooltip() {
            this.shouldShowTooltip = true;
            this.tooltipInstance.show();
        },
        hideTooltip() {
            this.shouldShowTooltip = false;
            this.tooltipInstance.hide();
        },
    },
};
</script>

<style lang="scss">
[data-theme='tooltip'] {
    background-color: var(--light-highlight);
    border: 1px solid var(--light-border);
    color: var(--text-color);
    text-align: center;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    * {
        padding: 0;
        margin: 0;
    }
}
</style>